import _ from "lodash"
import { useToast } from "@chakra-ui/react"
import VisibleWrapper from "../VisibleWrapper/VisibleWrapper"
import { useDispatch } from "react-redux"
import { dispatchAction } from "@dladio/app-bridge"
import { salesOrderSchema } from "../../schema/orderCreateValidation"
import { placeOrderAction } from "../../actions"
import { MODES_OF_DELIVERY } from "../../data/mode-of-delivery"
import { DefaultCommandBar } from "@dladio/core-ui"
import { useForm, useLocalStore } from "@dladio/hooks"
import { SalesOrderRequestFactory } from "../../actions/SalesOrderRequestFactory/SalesOrderRequestFactory"
import { additionalExpensesFactory, getOrderProcessingFee, orderSummaryFactory } from "../../actions/Calculations/orderProcessingCalculations"

const Footer = () => {

    const toast = useToast()
    const dispatch = useDispatch()
    const { form } = useForm()
    const { select } = useLocalStore('so')

    let bpInfo = select('bpInfo', select)

    let orderSummary = orderSummaryFactory(select("finalSoLines"));
    let additionalExpenses = additionalExpensesFactory(MODES_OF_DELIVERY, _.get(form, 'modes_of_delivery'))
    let orderProcessingFee = getOrderProcessingFee(_.get(form, 'shippingAddress'), bpInfo("IsOrderProcessingFeeApplied"), orderSummary, _.get(form, 'modes_of_delivery'))

    const createOrder = async () => {

        dispatchAction({
            action: 'loading',
            payload: true
        })
        const res = SalesOrderRequestFactory(_.get(form, 'billingAddress'), _.get(form, 'shippingAddress'), form, bpInfo('CardCode'), orderProcessingFee, additionalExpenses, select("finalSoLines"))
        let isValid = await salesOrderSchema.isValid(res)

        if (select("finalSoLines")?.length > 0) {
            if (isValid) dispatch(placeOrderAction(res, toast) as any)
            else {
                dispatchAction({
                    action: 'loading',
                    payload: false
                })

                toast({
                    title: 'Form invalid!',
                    status: 'error',
                    duration: 2500,
                    isClosable: true,
                })
            }

        } else {
            dispatchAction({
                action: 'loading',
                payload: false
            })

            toast({
                title: 'Please insert items',
                status: 'error',
                duration: 2500,
                isClosable: true,
            })


        }
    }

    return (
        <>
            <VisibleWrapper>
                <DefaultCommandBar actions={[
                    {
                        key: 3,
                        text: 'Save',
                        iconProps: { iconName: 'Save' },
                        onClick: () => { createOrder() },
                    }
                ]} />
            </VisibleWrapper>
        </>
    )
}

export default Footer