import _ from "lodash";
import VisibleWrapper from "../VisibleWrapper/VisibleWrapper";
import {useLocalStore} from "@dladio/hooks";
import {TupleCard, TupleCardLine} from "@dladio/core-ui";

const AdditionalInformation = () => {
    const { select } = useLocalStore('so')

    let bpInfo = select("bpInfo", select);

    return (
        <>
            <VisibleWrapper>
                <TupleCard title='Additional Information'>
                    <TupleCardLine title={'Price point notes'} value={bpInfo("U_PricePointNotes")} />
                    <TupleCardLine title={'Special notes'} value={bpInfo("U_SpecialNotes")} />
                    <TupleCardLine title={'Shipping instruction'} value={bpInfo("U_ShippingInstructions")} />
                </TupleCard>
            </VisibleWrapper>
        </>
    )
}

export default AdditionalInformation