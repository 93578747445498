import _ from "lodash"
import { useToast } from "@chakra-ui/react"
import { useDispatch } from "react-redux"
import CreditLimitAlert from "../CreditLimitAlert/CreditLimitAlert"
import { dispatchAction } from "@dladio/app-bridge"
import { placeOrderAction } from "../../actions"
import { salesOrderSchema } from "../../schema/orderCreateValidation"
import { MODES_OF_DELIVERY } from "../../data/mode-of-delivery"
import { SalesOrderRequestFactory } from "../../actions/SalesOrderRequestFactory/SalesOrderRequestFactory"
import { DefaultCommandBar, TitleBar } from "@dladio/core-ui"
import { localStoreThunk, useForm, useLocalStore } from "@dladio/hooks"
import { additionalExpensesFactory, getOrderProcessingFee, orderSummaryFactory } from "../../actions/Calculations/orderProcessingCalculations"

const { setStore: setPanel } = localStoreThunk('item-history-panel')

const Header = () => {

    const toast = useToast()
    const dispatch = useDispatch()
    const { form } = useForm()
    const { select } = useLocalStore('so')

    let bpInfo = select('bpInfo', select)

    let orderSummary = orderSummaryFactory(select("finalSoLines"));
    let additionalExpenses = additionalExpensesFactory(MODES_OF_DELIVERY, _.get(form, 'modes_of_delivery'))
    let orderProcessingFee = getOrderProcessingFee(_.get(form, 'shippingAddress'), bpInfo("IsOrderProcessingFeeApplied"), orderSummary, _.get(form, 'modes_of_delivery'))

    const createOrder = async () => {

        dispatchAction({
            action: 'loading',
            payload: true
        })

        const res = SalesOrderRequestFactory(_.get(form, 'billingAddress'), _.get(form, 'shippingAddress'), form, bpInfo('CardCode'), orderProcessingFee, additionalExpenses, select("finalSoLines"))
        let isValid = await salesOrderSchema.isValid(res)

        if (select("finalSoLines")?.length > 0) {
            if (isValid) dispatch(placeOrderAction(res, toast) as any)
            else {
                dispatchAction({
                    action: 'loading',
                    payload: false
                })

                toast({
                    title: 'Form invalid!',
                    status: 'error',
                    duration: 2500,
                    isClosable: true,
                })
            }

        } else {
            dispatchAction({
                action: 'loading',
                payload: false
            })

            toast({
                title: 'Please insert items',
                status: 'error',
                duration: 2500,
                isClosable: true,
            })


        }
    }

    return (
        <>
            <TitleBar title="Create Sales Order" subTitle={"SALES - AR"} />
            <DefaultCommandBar actions={[
                {
                    key: 1,
                    text: 'Back',
                    iconProps: { iconName: 'Back' },
                    onClick: () => {
                        dispatchAction({
                            action: 'navigate',
                            payload: -1
                        }, "https://helios.dlad.io/")
                    },
                },
                {
                    key: 2,
                    text: 'Item Order History',
                    iconProps: { iconName: 'View' },
                    onClick: () => { dispatch(setPanel('isOpen', true) as any) },
                },
                {
                    key: 3,
                    text: 'Save',
                    iconProps: { iconName: 'Save' },
                    onClick: () => { createOrder() },
                }
            ]} />
            <CreditLimitAlert />
        </>
    )
}

export default Header