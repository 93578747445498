import Header from "./components/Header/Header"
import Footer from "./components/Footer/Footer"
import Address from "./components/Address/Address"
import Summery from "./components/Summery/Summery"
import GradesAndStatus from "./components/Grades&Status/GradesAndStatus"
import AddSalesOrderLine from "./components/SalesOrderLineTable/components/AddSalesOrderLine/AddSalesOrderLine"
import SalesOrderLineTable from "./components/SalesOrderLineTable/SalesOrderLineTable"
import OrderInformationForm from "./components/OrderInformationFrom/OrderInformationForm"
import { Box, Grid, Masonry } from "@dladio/core-ui"
import AccountInformationForm from "./components/AccountInformationForm/AccountInformationForm"
import BasicAccountInformation from "./components/BasicAccountInformation/BasicAccountInformation"
import ShippingInformationForm from "./components/ShippingInformation/ShippingInformation"
import ExtraChargesAndTradingTerms from "./components/ExtraCharges/ExtraChargesAndTradingTerms"
import AdditionalInformation from "./components/AdditionalInfomation/AdditionalInformation";

const Home = () => {
    return (
        <>
            <Header />
            <Masonry>
                <Box title="Account Information">
                    <AccountInformationForm />
                </Box>
                <Box title="Order Information">
                    <OrderInformationForm />
                </Box>
                <BasicAccountInformation />
                <Address />
                <GradesAndStatus />
                <ExtraChargesAndTradingTerms />
                <ShippingInformationForm />
                <AdditionalInformation />
            </Masonry>
            <SalesOrderLineTable />
            <Grid>
                <AddSalesOrderLine />
                <Summery />
            </Grid>
            <Footer />
        </>
    )
}

export default Home